<template>
  <div v-if='isViewMode'
     class='whitespace-pre-line'>{{field.value}}</div>
  <textarea v-else
    v-model='field.value'
    @blur='handleUpdate'
    rows='3'
    class='form-textarea'></textarea>
</template>

<script>

export default {
  name: 'CustomFieldParagraph',
  props: ['field', 'isViewMode'],
  methods: {
    handleUpdate () {
      this.$emit('update-value', this.field)
    }
  }
}
</script>
